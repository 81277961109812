import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IPageStoryFields } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { StyledImage } from 'bl-common/src/units/SmartImage/StyledImage'
import { media, mediaMax } from 'bl-common/src/utils/media'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { getRatio } from './helpers'

const Content = styled.div`
  box-sizing: border-box;
`

const ImageWrap = styled.div`
  overflow: hidden;
`

const Image = styled(StyledImage)<{ ratio: number }>`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  opacity: 0.9;

  &::before {
    content: '';
    display: block;
    padding-bottom: ${({ ratio }) => ratio * 100}%;
  }

  transition:
    opacity ${durations.short}ms,
    transform ${durations.long}ms;
`

const Wrapper = styled.div`
  transition: transform ${durations.long}ms ease;
`

// OuterLink is the main hover trigger
const OuterLink = styled(({ ...rest }) => <Link {...rest} />)`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  ${media.md(css`
    &:hover {
      & + ${Wrapper} > ${ImageWrap} > ${Image} {
        transform: scale(1.1);
        opacity: 1;
      }
      & + ${Wrapper} {
        transform: translateY(-8px);
      }
    }
  `)}
`

const InnerLink = styled(({ ...rest }) => <Link {...rest} />)`
  position: relative;
  z-index: 2;
  display: block;

  &:hover {
    color: ${colors.softBlue};
  }
`

const Container = styled.div<{ breakOnMobile: boolean }>`
  background-color: transparent;
  display: block;
  width: 100%;
  position: relative;
  z-index: 0;

  ${({ breakOnMobile }) =>
    breakOnMobile &&
    mediaMax.md(css`
      display: flex;
      align-items: center;
      > * {
        flex: 1;
        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    `)}
`

const ArticleDate = styled(Type)`
  display: flex;
  align-items: center;
  opacity: 0.5;

  &::before {
    content: '';
    height: 1px;
    width: 10px;
    background: currentColor;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.5;

    ${media.md(css`
      width: 16px;
      height: 2px;
    `)}
  }
`

type StoryCardProps = {
  title: IPageStoryFields['title']
  date: IPageStoryFields['publishDate']
  topic: IPageStoryFields['topic']
  image: IPageStoryFields['heroImage']['fields']['image']
  imageRatio: number
  link: IPageStoryFields['slug']
  isSmall?: boolean
  breakOnMobile?: boolean
  withDate?: boolean
}

export const StoryCard = ({
  title,
  date,
  topic,
  image,
  imageRatio,
  link,
  isSmall,
  breakOnMobile = false,
  withDate = true,
}: StoryCardProps) => {
  const topicName = get(topic, 'fields.topicName', '')
  const topicSlug = get(topic, 'fields.topicSlug', '')
  const { i18n, t } = useTranslation()
  return (
    <Appear styleChild>
      <Container breakOnMobile={breakOnMobile}>
        <OuterLink
          to={t('storyPathWithSlug', { slug: link })}
          aria-label={`${title}. ${t('readMore')}`}
        />
        <Wrapper>
          <ImageWrap>
            <Image image={image} ratio={imageRatio || getRatio(image)} />
          </ImageWrap>
          <Content>
            <Type
              preset="labelSmall"
              weight="bold"
              color={colors.deepBlue}
              top={{ xs: breakOnMobile ? 1 : 2, md: 2 }}
              bottom={{ xs: 1 }}
            >
              {topicSlug && (
                <InnerLink to={t('storyTopicPath', { topicSlug })}>
                  {topicName}
                </InnerLink>
              )}
            </Type>
            <Type
              preset={isSmall ? 'headlineSmall' : 'headlineMedium'}
              as="h3"
              bottom={{ xs: breakOnMobile ? 0.5 : 1, md: 1 }}
            >
              {title}
            </Type>
            {withDate && (
              <ArticleDate preset={isSmall ? 'textSmall' : 'text'}>
                {formatDateInUTC(date, 'PP', i18n.language)}
              </ArticleDate>
            )}
          </Content>
        </Wrapper>
      </Container>
    </Appear>
  )
}

StoryCard.defaultProps = {
  link: '/stories',
}
