import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { media } from '../../utils/media'

const ControlButton = styled.button`
  display: block;
  appearance: none;
  background: ${colors.lagoonBlue};
  border: 0;
  cursor: pointer;
  opacity: 0.25;
  height: 5px;
  outline: 0;
  position: relative;
  width: ${({ theme }) => theme.spacing[2.5]};
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.025);

  &:focus {
    outline: 0;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 2rem 0;
  }

  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  .gradient-dark & {
    background: ${colors.white};
  }

  .gradient-light & {
    background: ${colors.lagoonBlue};
  }
`

const ActiveIndicator = styled(ControlButton)`
  opacity: 1;
  position: absolute;
  left: 0;
  pointer-events: none;
  transition: transform ${durations.medium}ms;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.md(css`
    margin-top: ${({ theme }) => theme.spacing[1.5]};
  `)};
`

const ControlWrap = styled.div`
  display: inline-flex;
  height: 20px;
  position: relative;
`

export const PageControl = ({
  pageCount,
  currentPage = 0,
  scrollTo,
  callback = () => {},
}) => {
  return (
    <Container>
      <ControlWrap>
        {pageCount > 1 &&
          Array.from(
            {
              length: pageCount,
            },
            (_, index) => (
              <ControlButton
                key={`Page ${index + 1}`}
                onClick={() => {
                  scrollTo(index)
                  callback()
                }}
                aria-label={`Page ${index + 1}`}
              />
            )
          ).concat(
            <ActiveIndicator
              key="activeIndicator"
              as="span"
              style={{
                transform: `translateX(${
                  currentPage * 100
                }%) translateX(${currentPage}rem)`,
              }}
            />
          )}
      </ControlWrap>
    </Container>
  )
}
