import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { durations } from '../../constants/durations'
import { gradients } from '../../constants/gradients'
import { zIndex } from '../../constants/zIndex'
import { Amount } from '../../elements/Amount'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import { StyledImage } from '../../units/SmartImage/StyledImage'
import { media } from '../../utils/media'

type ImageWrapProps = {
  layout?: string
}

const Container = styled(
  ({ gradient, offsetDirection, evenHeight, ...rest }) => <Link {...rest} />
)`
  position: relative;
  display: block;
  z-index: ${zIndex.above};
  margin-bottom: ${({ theme: { spacing } }) => `${spacing[2.5]}`};

  ${({ evenHeight }) =>
    evenHeight &&
    media.mlg(css`
      display: flex;
      flex-direction: column;
      height: 95%;
    `)}

  &::after {
    content: '';
    position: absolute;
    z-index: ${zIndex.behind};
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${({ gradient }) => get(gradient, 'fields.degrees', '0deg')},
      ${({ gradient }) =>
          get(gradient, 'fields.color1', gradients.softGradient5[0])}
        2.43%,
      ${({ gradient }) =>
          get(gradient, 'fields.color2', gradients.softGradient5[1])}
        97.27%
    );

    ${({ theme: { spacing }, offsetDirection }) => css`
      top: ${spacing[1]};
      left: ${offsetDirection === 'left' ? spacing[-1] : spacing[1]};
    `}
  }
`

const Card = styled.div`
  background: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 24px;
  ${media.md(css`
    padding: ${({ theme: { spacing } }) => `${spacing[1.5]} ${spacing[1]}`};
  `)}
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ImageWrap = styled.div<ImageWrapProps>`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ layout }) => (layout === 'Portrait' ? '121%' : '88%')};
`

const BackgroundImage = styled(StyledImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);

  transition: transform ${durations.long}ms;

  ${media.md(css`
    ${Container}:hover & {
      transform: scale(1.035);
    }
  `)}
`

export const SmallGradientOffsetCard = ({
  image,
  title,
  description = undefined,
  layout = 'Portrait',
  price = undefined,
  priceRef = undefined,
  priceFormat = undefined,
  url = undefined,
  primaryCtaUrl,
  secondaryCtaUrl = undefined,
  offsetDirection = undefined,
  evenHeight,
  gradient,
}) => (
  <Container
    to={url || primaryCtaUrl || secondaryCtaUrl || '/'}
    offsetDirection={offsetDirection}
    evenHeight={evenHeight}
    gradient={gradient}
  >
    {image && (
      <SmartImage image={image}>
        {imageUrl => (
          <ImageWrap layout={layout}>
            <BackgroundImage image={imageUrl} />
          </ImageWrap>
        )}
      </SmartImage>
    )}
    <Card>
      <Content>
        <Type
          preset="labelSmall"
          weight="bold"
          case="uppercase"
          bottom={{
            xs: 0.5,
            md: 0.5,
          }}
        >
          {title}
        </Type>
        <Type size={{ xs: 12 }} multiline>
          {description}
        </Type>
        {(priceRef || price) && (
          <Type
            preset="textSmall"
            case="uppercase"
            weight="bold"
            top={{
              xs: 2,
            }}
          >
            <Amount value={priceRef} format={priceFormat} fallback={price} />
          </Type>
        )}
      </Content>
    </Card>
  </Container>
)
