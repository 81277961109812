import { SmartImage } from './SmartImage'

type StyledImageProps = {
  image: any
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const StyledImage = ({
  image,
  className,
  children,
  style,
}: StyledImageProps) => (
  <SmartImage image={image} styles>
    {({ className: imageClassName, style: imageStyle }) => (
      <div
        style={{ ...imageStyle, ...style }}
        className={`${imageClassName} ${className || ''}`}
      >
        {children}
      </div>
    )}
  </SmartImage>
)
