import { Children } from 'react'
import styled, { css } from 'styled-components'

import { nestedCalc } from 'bl-utils/src/nestedCalc'

import { breakpoints } from '../../constants/breakpoints'
import {
  gridOffsetFrom,
  gridOffsetTo,
  modularScale,
} from '../../constants/sizes'
import { between } from '../../utils/between'
import { media } from '../../utils/media'
import { mixins } from '../../utils/mixins'

// This may look crazy, and may well be crazy, but we're generating media queries dynamically to
// make more and more items of a certain target width fit the screen.
// This function returns the minimum page width that fits `count` items with `itemWidth`. It is
// complicated by the fact that pages have a width-dependent margin, and whitespace between the
// items is half of that page margin.
const calcPageWidthForCount = (
  minItemWidthFrom,
  minItemWidthTo,
  count,
  paddingMultiplier
) => {
  // formula:
  // minItemWidth = minItemWidthBase + minItemWidthSlope * viewportWidth
  // padding = paddingBase + paddingSlope * viewportWidth
  // paddingCount = (2 + (count - 1) / 2)
  // viewportWidth = count * itemWidth + paddingCount * padding * paddingMultiplier
  //
  // Solved for width here: https://www.symbolab.com/solver/solve-for-equation-calculator/?or=dym&query=solvefor%20W%2CW%3Dc(B%2BSW)%2Bp(2%2B%5Cfrac%7B(c-1)%7D%7B2%7D)(b%2BsW)
  // width = -(3*paddingMultiplier*paddingBase + paddingMultiplier*count*paddingBase + 2*minItemWidthBase*count) / (-2 + 2*minItemWidthSlope*count + 3*paddingSlope*paddingMultiplier + paddingSlope*paddingMultiplier*count)

  const minItemWidthSlope =
    (minItemWidthTo - minItemWidthFrom) / (breakpoints.lg - breakpoints.sm)
  const minItemWidthBase = minItemWidthFrom - minItemWidthSlope * breakpoints.sm
  const paddingSlope =
    (gridOffsetTo - gridOffsetFrom) / (breakpoints.lg - breakpoints.sm)
  const paddingBase = gridOffsetFrom - paddingSlope * breakpoints.sm
  return Math.round(
    -(
      3 * paddingMultiplier * paddingBase +
      paddingMultiplier * count * paddingBase +
      2 * minItemWidthBase * count
    ) /
      (-2 +
        2 * minItemWidthSlope * count +
        3 * paddingSlope * paddingMultiplier +
        paddingSlope * paddingMultiplier * count)
  )
}

type ItemWrapProps = {
  width?: number
  pageMargin?: number
  minItemWidthFrom?: number
  minItemWidthTo?: number
  endWidth?: number
}

type ContainerProps = {
  pageMargin?: number
  endWidth?: number
}

const ItemWrap = styled.div<ItemWrapProps>`
  box-sizing: content-box;
  flex: none;

  scroll-snap-align: start;
  width: ${props => {
    return props.width
      ? props.width
      : nestedCalc(`
      100vw -
      ${between(
        gridOffsetFrom * 2 * props.pageMargin,
        gridOffsetTo * 2 * props.pageMargin
      )}`)
  }};
  padding: 0
    ${props =>
      between(
        (gridOffsetFrom * props.pageMargin) / 4,
        (gridOffsetTo * props.pageMargin) / 4
      )};

  ${({ minItemWidthFrom, minItemWidthTo, pageMargin, endWidth }) => {
    if (!minItemWidthFrom) {
      return
    }
    let items = 2
    const mqs = []
    let minWidth = calcPageWidthForCount(
      minItemWidthFrom,
      minItemWidthTo,
      items,
      pageMargin
    )
    while (minWidth < endWidth) {
      const maxWidth = calcPageWidthForCount(
        minItemWidthFrom,
        minItemWidthTo,
        items + 1,
        pageMargin
      )
      const mq = [`(min-width: ${minWidth}px)`]
      if (maxWidth < endWidth) {
        mq.push(`(max-width: ${maxWidth - 1}px)`)
      }

      const paddingCount = pageMargin * (2 + (items - 1) / 2)
      const itemWidth = `(100vw - ${between(
        gridOffsetFrom * paddingCount,
        gridOffsetTo * paddingCount
      )}) / ${items}`
      mqs.push(`
        @media ${mq.join(' and ')} {
          scroll-snap-align: none;
          width: ${nestedCalc(itemWidth)};

          &:nth-child(${items}n + 1) {
            scroll-snap-align: start;
          }
        }
      `)

      minWidth = maxWidth
      items++
    }
    return mqs.join('\n')
  }};
`

const Container = styled.div<ContainerProps>`
  display: flex;
  margin: 0
    ${props =>
      between(
        -gridOffsetFrom * props.pageMargin,
        -gridOffsetTo * props.pageMargin
      )};
  overflow-x: scroll;
  padding-bottom: ${({ theme }) => theme.spacing[2]};
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: ${props =>
    between(
      gridOffsetFrom * (props.pageMargin * 0.75),
      gridOffsetTo * (props.pageMargin * 0.75)
    )};
  ${mixins.disableScrollbarOnMobile()};
  &::before,
  &::after {
    content: '';
    flex: none;
    width: ${props =>
      between(
        gridOffsetFrom * props.pageMargin * 0.75,
        gridOffsetTo * props.pageMargin * 0.75
      )};
  }

  ${media.md(css`
    padding: ${between(32 / modularScale, 32)} 0;
  `)};
`

export const ScrollContainer = ({
  children,
  touchScrolling,
  containerRef,
  minItemWidthFrom,
  minItemWidthTo,
  pageMargin,
  endWidth,
  onContainerScroll,
  width,
}) => (
  <Container
    ref={containerRef}
    pageMargin={pageMargin}
    onScroll={onContainerScroll}
    endWidth={endWidth}
    style={{
      overflowX: !touchScrolling ? 'hidden' : 'scroll',
      scrollSnapType: !touchScrolling ? 'none' : 'x mandatory',
    }}
  >
    {Children.map(children, child => (
      <ItemWrap
        minItemWidthFrom={minItemWidthFrom}
        minItemWidthTo={minItemWidthTo}
        pageMargin={pageMargin}
        endWidth={endWidth}
        data-carouselitemwrap={true} // used for styling in sections see: comparisonCardsSection
        width={width}
      >
        {child}
      </ItemWrap>
    ))}
  </Container>
)
